<template>
  <section class="faq">
    <div class="page-header">
      <h3 class="page-title">
        Объект {{ rldata['objcode'] }}
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">Добавить QR</li>
          <li class="breadcrumb-item active" aria-current="page"><router-link class="" v-bind:to="'/'"  >Назад</router-link></li>  <!--  v-bind:to="'/lkparent/faqp'+'?t='+$root.add_v()"   -->
        </ol>
      </nav>
    </div>
      
      
  
         
    <div class="row">
        
        
        
      
     <!-- COL1 -->   
      <div class="col-md-12">
        <div class="row">
           
            
           
          <div class="col-12 grid-margin">
            <div class="card" v-if="rldata['is_table']">
              <div class="faq-block card-body">
              
                  <h5>Локация</h5>
                  <p>Где будет находиться QR?</p>
                  <b-form-textarea id="qrinfo" v-model="rldata.qrinfo" :style="error_qrinfo_style"  placeholder="3 этаж, офис 34" rows="2" max-rows="6"></b-form-textarea>
                  
                  <p class="pt-2 text-danger">Внимание! Тект будет виден клиенту!</p>
                   <b-button class="btn btn-danger btn-sm mr-3 mt-2 mb-2" v-on:click="addqrcode"   :disabled="check_is_act_disabled"   >+ QR</b-button>
                  
                   <p class="text-gray ">Последний добавленный QR: {{ rldata.last_insert_qrinfo }}</p> 
                  
                  <p v-if="rldata.flagserror_common.is_error_in_sending_data" class="text-danger">{{ this.$root.error_text.text_error_in_sending_data }}</p>
                  <p v-if="rldata.flagserror_common.is_error_execution_api" class="text-danger" >{{ this.$root.error_text.text_error_execution_api }}</p>
                  <p v-if="rldata.flagserror_action.is_error_faild_limit" class="text-danger" >{{ rldata['flagserror_action']['text_error_faild_limit'] }}</p>
                    
                  
                  
              
              </div>
            </div>
          </div>
           
        
            
            
            
            
        </div>
      </div>
    
       <!-- COL1 -->  
    </div> 
      
       
       
       
       
       
       
       
       
       
       
     <div class="row">     
       
       <!-- COL2 --> 
      <div class="col-md-12">
        <div class="row">
            
            
            
            
                  
            
           
          <div class="col-12 grid-margin">
            <div class="card">
              <div class="faq-block card-body">
                
                  
                  <div  class="row" v-if="rldata['is_loaded_qrcodes']"> 
                           
                           
                        <div class="card" v-if="rldata['is_table']">
                              <div class="card-body pb-0">
                                <h4 class="card-title mb-4">Список существующих QR кодов</h4>
                              </div>
                              <div class="card-body p-0">
            
                                  

                                <div class="table-responsive">
                                  <table class="table custom-table text-dark">
                                    <thead>
                                      <tr>
                                        <th>QR</th>    
                                        <th>Порядок</th>
                                        <th>objcode</th>
                                        <th>loccode</th>    
                                        <th>Отм</th>  
                                        <th>Локация</th>  
                                        <th>Коммент.</th>   
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(item, index) in rldata['a_qrcodes']" :key="item.idqr" v-bind:class='"text-gray"' >
                                        
                                              <td>
                                                <b-button :id="'qr_'+item.idqr"  class="btn  btn-sm mr-3 mt-2 mb-2"     v-on:click="show_qr" v-bind:qrguid="item.qrguid"  v-bind:class='"btn-danger"'   >
                                                       QR 
                                                </b-button>
                                        </td>  
                                          
                                          
                                          
                                          <td> 
                                            <div    class="d-flex flex-row">
                                                <b-button :disabled="rldata['flags_logic']['is_disabled_up_down_buttons']"  :id="'qrord_up_'+item.idqr"  class="btn    btn-sm  mt-2 mb-2 ml-2 mr-2"     v-on:click="qrord_up" v-bind:loccode="item.loccode" v-bind:qrguid="item.qrguid"  v-bind:class='"btn-light"'    >&uarr;</b-button>
                                          <!--      <div class="overflow-hidden pt-2 ml-2 mr-1 "   style="width:15px;">{{ (!!parseInt(item.is_active)?item.qrord:item.qrord  ) }}</div>-->
                                                <b-button :disabled="rldata['flags_logic']['is_disabled_up_down_buttons']" :id="'qrord_down_'+item.idqr"  class="btn    btn-sm  mr-2 mt-2 mb-2 "     v-on:click="qrord_down" v-bind:loccode="item.loccode" v-bind:qrguid="item.qrguid"  v-bind:class='"btn-light"'    >&darr;</b-button>
                                           </div>
                                            
                                        </td>
                                        <td>
                                              {{item.objcode}}
                                        </td>
                                        <td> <router-link    class="   " v-bind:to="item.qrlink">{{item.loccode}} <!-- ({{item.loccode_letter}}/{{item.loccode_number}})-->  </router-link>  </td>
                                           
                                  
                                     
                                        <td>  
                                            <b-button v-if='!!parseInt(item.is_marked)' :id="'remove_mark_'+item.idqr"  class="btn  btn-sm mr-3 mt-2 mb-2"   v-bind:qrguid="item.qrguid" v-bind:is_marked="item.is_marked" v-on:click="change_mark" v-bind:loccode="item.loccode"  v-bind:class='"btn-gray"'   >
                                                !
                                            </b-button>
                                            <b-button v-else                            :id="'set_mark_'+item.idqr"     class="btn  btn-sm mr-3 mt-2 mb-2"   v-bind:qrguid="item.qrguid" v-bind:is_marked="item.is_marked" v-on:click="change_mark" v-bind:loccode="item.loccode"  v-bind:class='"btn-light"'   >
                                                + 
                                            </b-button>
                                        
                                        </td>
                                        
                                        <td>  <b-input :id="'qrinfo_'+item.idqr" v-model="item.qrinfo"   placeholder="Локация"  >{{item.qrinfo}} </b-input> </td>
                                       
                                      
                                        
                                    
                                        <td> 
                                            <div class="d-flex flex-row">
                                                <b-input :id="'qrcomment_'+item.idqr" v-model="item.qrcomment"   placeholder="Комментарий"  > </b-input>
                                                 <b-button  :id="'update_qrrow_'+item.idqr"  class="btn    btn-sm  mr-3 mt-2 mb-2 ml-2"     v-on:click="update_qrrow" v-bind:qrguid="item.qrguid"  v-bind:class='"btn-gray"'    >
                                                   Сохранить
                                                </b-button>
                                            </div>
                                        </td>
                                        
                                    
                                        
                                      </tr>
                                     
                                      
                                    </tbody>
                                  </table>
                                </div>




                                
                              </div>
                            </div>

                      
                             
                        <div class="card" v-if="rldata['is_qr']">
                              <div class="card-body pb-0">
                                <h4 class="card-title mb-4">{{ this.rldata['r_qrcodes']['objcode'] }} / {{ this.rldata['r_qrcodes']['loccode'] }}</h4>
                              </div>
                              <div class="card-body p-0">
            
                                <div class="table-responsive">
                                  <table class="table custom-table text-dark">
                                    <thead>
                                      <tr>
                                        <th>grguid</th> 
                                        <th>Описание</th>
                                        <th>objcode</th>
                                        <th>loccode</th>
                                        <th>Ссылка QR-кода</th> 
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{{ rldata['r_qrcodes']['qrguid'] }} </td> 
                                        <td>{{ rldata['r_qrcodes']['qrinfo'] }} </td> 
                                        <td>{{ rldata['r_qrcodes']['objcode'] }} </td>  
                                        <td>{{ rldata['r_qrcodes']['loccode'] }} </td>  
                                        <td>
                                           <!-- <a class="" v-bind:href="rldata['cloud_url']+rldata['r_qrcodes']['qrlink']">{{rldata['cloud_url']+rldata['r_qrcodes']['qrlink']}}</a>  -->
                                           <router-link   class="  " v-bind:to="rldata['r_qrcodes']['qrlink']">{{rldata['r_qrcodes']['qrlink']}}</router-link>
                                        </td> 
                                      </tr>
                                    </tbody>
                                  </table> 
                                </div>
                                  
                                  
                                      <b-button   class=" btn-sm btn-danger  mr-3 mt-2 ml-2 mb-3"     v-on:click="show_table"      >
                                                Назад
                                         </b-button>
                                  
                                  
                                  <table class="pt-2 pb-3">
                                      <tr>
                                         <td class="text-center">
                                             <p>На считывается QR-код? Не беда!</p><p> Оставьте заявку на сайте <b>{{ rldata['r_qrcodes']['objcode'].toLowerCase() +".lvrc.ru/"+rldata['r_qrcodes']['loccode'].toUpperCase() }}</b></p>
                                         </td> 
                                       </tr> 
                                       <tr>                                         
                                         <td class="text-center"><img v-bind:src="rldata['qr_png_4']" alt="image" /></td> 
                                       </tr>  
                                        <tr>                                         
                                         <td class="text-center"><img v-bind:src="rldata['qr_png_6']" alt="image" /></td> 
                                       </tr>  
                                        <tr>                                         
                                         <td class="text-center"><img v-bind:src="rldata['qr_png_8']" alt="image" /></td> 
                                       </tr>  
                                       <tr>
                                         <td class="text-center">
                                             <p>На считывается QR-код? Не беда!</p><p> Оставьте заявку на сайте <b>{{ rldata['r_qrcodes']['objcode'].toLowerCase() +".lvrc.ru/"+rldata['r_qrcodes']['loccode'].toUpperCase() }}</b></p>
                                         </td> 
                                       </tr> 
                                  </table>  
                                        
                                  
                                  
                                         <b-button   class=" btn-sm btn-danger  mr-3 mt-3 ml-2 mb-2"     v-on:click="show_table"      >
                                                Назад
                                         </b-button>


                                
                              </div>
                            </div>

                      
                      
                      
                      
      </div>
                      
                      
                      
                  
                  
                  </div>    
                  
                  
           
              </div>
            </div>
          </div>
           
              
      
            
            
            
            
       
        
            
            
            
        
            
        </div>
      </div>
       <!-- COL1 --> 
     
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
    </div>
  
  
  
  
  </section>
</template>

<script>
import $ from 'jquery'
import axios from 'axios';
import axiosRetry from 'axios-retry';       
      

var data = {
    objcode: '',
    qrinfo_limit : 0,
    a_qrcodes: [],
    
    is_table:true,
    is_qr: false,
    r_qrcodes : [],
    cloud_url : "",
    cloud_domain : "",
    
    qr_png_4: "",
    qr_png_6: "",
    qr_png_8: "",
    
    qrinfo:'',
    last_insert_qrinfo:'',
    is_loaded_qrcodes: false,
       
   
    
    flags_logic: {
        is_act_disabled : false,
        is_disabled_up_down_buttons : false,
    },    
    flagserror_common: {
            is_error_in_sending_data: false,
            is_error_execution_api: false,
    },    
    flagserror_action: {
            is_error_faild_limit: false,
            text_error_faild_limit: " Пул номеров в 260 штук в диапазоне A0-Z9 закончались, далее ручной ввод",
    },
    
    
}      
        
export default {
  name: 'addqr',
   
    data () { //тюненгуемые переменные и функции
        return {
            rldata: data,  
        };
    },
  
  
  mounted () {
   
   // this.$root.check_version(this.rldata, true);
   
       
         //получить данные о пользователе из ls
    var url = new URL(window.location.href);    
    this.rldata['objcode']=url.searchParams.get("objcode").toUpperCase();     
    localStorage.setItem(this.rldata['objcode'],true);
    localStorage.setItem('objcode', this.rldata['objcode']);
    //this.rldata['objcode']=localStorage.getItem('objcode');
    //if (this.rldata['objcode']===null) ...
    
         
    this.rldata['is_table']=true;    
    this.rldata['is_qr']=false;   
    this.rldata['cloud_url']=this.$root.rdata['cloud_url'];
    this.rldata['cloud_domain']=this.$root.rdata['cloud_domain'];     
    this.rldata['qrinfo_limit']=100;       
         
    this.$root.get_qrcodes_by_objcode(this.rldata); //a_qrcodes
         
   
   console.log(this.rldata['a_qrcodes']);
         
         
   this.rldata['flags_logic']['is_act_disabled']=false;
   this.rldata['flagserror_action']['is_error_faild_limit']=false;
   this.rldata['flagserror_common']['is_error_in_sending_data']=false;
   this.rldata['flagserror_common']['is_error_execution_api']=false;
  
  },  
  
  
  
  
  
  
methods: {
     
     
   
       qrord_down : function (event)   //(event) 
       {
           
           
              let loccode= event.target.getAttribute('loccode'); 
              let qrguid= event.target.getAttribute('qrguid');  
         
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        this.rldata['flags_logic']['is_disabled_up_down_buttons']=true;
       
      
        
         var url=this.$root.rdata['server_auth'];
                 
                  
             var params = {
                  method:  'common__addqr_qrord_down', 
                   data:  {
                     objcode : this.rldata['objcode'].trim(), 
                     loccode : loccode, 
                     qrguid : qrguid, 
                  } 
              }; 
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                            //  console.log(response['data']['data']['qrinfo']);
                               
                               this.rldata['a_qrcodes']=response['data']['data']['a_qrcodes'];
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                          /*  case "INCORRECT_CODE":
                                this.rldata['flagserror_action']['is_error_incorrect_code']=true;
                            console.log("INCORRECT_CODE");
                           break;*/
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false;
                   this.rldata['flags_logic']['is_disabled_up_down_buttons']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false;
                     this.rldata['flags_logic']['is_disabled_up_down_buttons']=false;
                    
            }).then(function () {  });
       
                
        
        
        
        
       },
                
        qrord_up : function (event)   //(event) 
       {
              let loccode= event.target.getAttribute('loccode'); 
              let qrguid= event.target.getAttribute('qrguid');  
        
         
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        this.rldata['flags_logic']['is_disabled_up_down_buttons']=true;
        
        
         var url=this.$root.rdata['server_auth'];
                 
                  
             var params = {
                  method:  'common__addqr_qrord_up', 
                   data:  {
                     objcode : this.rldata['objcode'].trim(), 
                     loccode : loccode, 
                     qrguid : qrguid,
                  } 
              }; 
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                            //  console.log(response['data']['data']['qrinfo']);
                               
                               this.rldata['a_qrcodes']=response['data']['data']['a_qrcodes'];
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                          /*  case "INCORRECT_CODE":
                                this.rldata['flagserror_action']['is_error_incorrect_code']=true;
                            console.log("INCORRECT_CODE");
                           break;*/
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false;
                   this.rldata['flags_logic']['is_disabled_up_down_buttons']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false;
                     this.rldata['flags_logic']['is_disabled_up_down_buttons']=false;
                    
            }).then(function () {  });
       
                
        
        
        
        
       },
                        
     
     
         
      show_qr : function (event)   //(event) 
    {  //        console.log(event['target']['id']);
        
         console.log("UP");
         let qrguid= event.target.getAttribute('qrguid'); 
         console.log(qrguid);
         
         
         let r_qrcodes="";
         console.log(qrguid);
         
        for (const [key, value] of Object.entries(this.rldata['a_qrcodes'])) { 
          if (value['qrguid']===qrguid)
          {   console.log("BINGO");
              console.log(value);
              r_qrcodes=value;
              break;
          }
        }
                   
        this.rldata['r_qrcodes']=r_qrcodes;
        this.rldata["is_table"]=false;
        this.rldata["is_qr"]=true;
        
        console.log("this.rldata['r_qrcodes']");  
          console.log(this.rldata['r_qrcodes']);  
        
         var url=this.$root.rdata['server_auth'];
       
        
       
                  
             var params = {
                  method:  'common__addqr_makeqr', 
                   data:  {
                     loccode : this.rldata['r_qrcodes']['loccode'],  
                     objcode : this.rldata['r_qrcodes']['objcode'], 
                     qrguid : this.rldata['r_qrcodes']['qrguid'], 
                     qrlink : this.rldata['r_qrcodes']['qrlink'], 
                  } 
              }; 
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                            //  console.log(response['data']['data']['qrinfo']);
                               
                               this.rldata['qr_png_4']=response['data']['data']['qr_png_4'];
                               this.rldata['qr_png_6']=response['data']['data']['qr_png_6'];
                               this.rldata['qr_png_8']=response['data']['data']['qr_png_8'];
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                          /*  case "INCORRECT_CODE":
                                this.rldata['flagserror_action']['is_error_incorrect_code']=true;
                            console.log("INCORRECT_CODE");
                           break;*/
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
                
        
        
        
        
        
        
        
    }, 
     
       show_table : function (event)   //(event) 
    {  //        console.log(event['target']['id']);
        
        
        this.rldata["is_table"]=true;
        this.rldata["is_qr"]=false;
        
        
    }, 
     
     
     
     
        
      update_qrrow : function (event)   //(event) 
    {  //        console.log(event['target']['id']);
        
         let qrguid= event.target.getAttribute('qrguid'); 
         let r_qrcodes="";
          
        for (const [key, value] of Object.entries(this.rldata['a_qrcodes'])) { 
          if (value['qrguid']===qrguid)
          {   console.log("BINGO");
              r_qrcodes=value;
              break;
              
          }
        }
        
        //console.log(input_val);
       
       
         
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        
       
      
        
         var url=this.$root.rdata['server_auth'];
                 
                  
             var params = {
                  method:  'common__addqr_update_qrrow', 
                   data:  {
                     objcode : this.rldata['objcode'].trim(), 
                     loccode : r_qrcodes['loccode'],
                     qrguid : r_qrcodes['qrguid'], 
                     qrcomment : r_qrcodes['qrcomment'],
                     qrinfo : r_qrcodes['qrinfo'],
                     
                  } 
              }; 
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                            //  console.log(response['data']['data']['qrinfo']);
                               
                               this.rldata['a_qrcodes']=response['data']['data']['a_qrcodes'];
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                          /*  case "INCORRECT_CODE":
                                this.rldata['flagserror_action']['is_error_incorrect_code']=true;
                            console.log("INCORRECT_CODE");
                           break;*/
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
                
        
        
        
        
        
         
    },
     
      change_mark : function (event)   //(event) 
    {  //        console.log(event['target']['id']);
        
         //let is_active= !!parseInt(event.target.getAttribute('is_active'));  TRUE FALSE 
         let is_marked=event.target.getAttribute('is_marked');
         let loccode= event.target.getAttribute('loccode');
         let qrguid= event.target.getAttribute('qrguid');
         
           this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        
        
        
         var url=this.$root.rdata['server_auth'];
                 
                  
             var params = {
                  method:  'common__addqr_change_mark', 
                   data:  {
                     objcode : this.rldata['objcode'].trim(),
                     loccode: loccode,
                     qrguid: qrguid, 
                     is_marked : is_marked 
                     
                  } 
              }; 
              
              console.log("is_marked="+is_marked);
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                             // console.log(response['data']['data']['qrinfo']);
                               
                               this.rldata['a_qrcodes']=response['data']['data']['a_qrcodes'];
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                          /*  case "INCORRECT_CODE":
                                this.rldata['flagserror_action']['is_error_incorrect_code']=true;
                            console.log("INCORRECT_CODE");
                           break;*/
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
                
        
        
        
        
        
         
    },
     
     
    
       
    addqrcode: function ()   //(event) 
    {        
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        this.rldata['flagserror_action']['is_error_faild_limit']=false;
        
        
        if (!this.check_is_act_disabled &&!this.error_qrinfo)
            {
                this.rldata['flags_logic']['is_act_disabled']=true;
                
             var url=this.$root.rdata['server_auth'];
                 
                  
             var params = {
                  method:  'common__addqr_add_qrcode', 
                   data:  {
                     objcode: this.rldata['objcode'].trim(), 
                     qrinfo: this.rldata['qrinfo'].trim(),
                  } 
              }; 
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                            //  console.log(response['data']['data']['qrinfo']);
                              
                               this.rldata['last_insert_qrinfo']=response['data']['data']['qrinfo'];
                               this.rldata['a_qrcodes']=response['data']['data']['a_qrcodes'];
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                            case "FAIL_LIMIT":
                                this.rldata['flagserror_action']['is_error_faild_limit']=true;
                                console.log("FAIL_LIMIT");
                           break;
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
                
                
            }
        
        
    },
   
},
  
  
 
   computed: { 
       
     check_is_act_disabled () { 
    //проверяет доступна ли кнопка действия (галка + должно быть завершено предидущее действие кнопки)
     if (this.rldata['flags_logic']['is_act_disabled'] || this.rldata['qrinfo'].trim().length<2 || this.rldata['qrinfo'].trim().length>this.rldata['qrinfo_limit'] ){//!39 лимит для QR-10 для M сжатия - 15% потери
        return true;//true - заблокирована
     }
     return false;
    },
    
    
    
    error_qrinfo_style () {
         if ( this.rldata['qrinfo'].trim().length<2 || this.rldata['qrinfo'].trim().length>this.rldata['qrinfo_limit']-5 )
         {
            return "background-color:#ffe5ed;";
         }
       return "";
   },
   error_qrinfo () {
         if ( this.rldata['qrinfo'].trim().length<2 || this.rldata['qrinfo'].trim().length>this.rldata['qrinfo_limit'] )   //!39 лимит для QR-10 для M сжатия - 15% потери
         {
            return true;
         }
       return false;
   },
    
    
    
  
},
  
}
</script>


<style scoped>
    .accordion .card .card-header a{
        padding-right: 1.5rem;
    }
    
    .anim-effect {
        transition: .5s;
    }
</style>